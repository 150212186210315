import React, {Component} from "react";
import { connect } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink} from "../../resources/url";
import AddFeedbackForm from "./addfeedbackform";
import Header from "../Header/header";

class UpdateAlumniFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: `${serverLink}public/uploads/alumni/${this.props.alumniData.ImagePath}`,
            formData: {
                id: this.props.alumniData.EntryID,
                StudentID: this.props.alumniData.StudentID,
                StudentName: this.props.alumniData.StudentName,
                image: this.props.alumniData.ImagePath,
                Title: this.props.alumniData.Title,
                Description: this.props.alumniData.Description === undefined ? "" : this.props.alumniData.Description,
                photo: this.props.alumniData.ImagePath,
                ImagePath: this.props.alumniData.ImagePath,
            },
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onUpdateFeedback();
    };

    async onUpdateFeedback() {
        let sendData = {
            ...this.state.formData,
        };

        if(this.state.formData.StudentID === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter StudentID", "error")
            return false;
        }

        if(this.state.formData.StudentName === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter student name", "error")
            return false;
        }

        if(this.state.formData.Title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter feedback title", "error")
            return false;

        }

        if(this.state.formData.Description === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter feedback content", "error")
            return false;
        }


        axios.post(`${serverLink}student/alumni/feedback/add`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "feedback exist") {
                    this.showAlert("Oops!", "Feedback already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the feedback. Please try again!", "error")
                    return false;
                } else if (response === "success"){

                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', result.data.entry_id)
                        axios.patch(`${serverLink}student/alumni/upload`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showSuccessAlert("SUCCESS", "ALUMNI FEEDBACK ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the feedback details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             ImagePath: url,
    //         },
    //     });
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '#/alumni/feedback/list';
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Alumni
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                Add Alumni Feedback
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3>ADD ALUMNI FEEDBACK</h3>
                                </div>
                            </div>
                            <AddFeedbackForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                onImageChange={this.onImageChange}
                                isLoading={this.state.isLoading}
                                data={this.state.formData}
                                image={this.state.htmlelement}
                            />
                        </div>
                        {/* CONTENT END */}
                    </div>
                </div>
            </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
       alumniData: state.generalDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateAlumniFeedback);
