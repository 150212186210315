import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import { serverLink } from "../../../resources/url";
import SliderForm from "./sliderform";
import Header from "../../Header/header";


class UpdateSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: `${serverLink}public/uploads/sliders_uploads/${this.props.sliderData.ImagePath}`,
            formData: {
                id: this.props.sliderData.EntryID,
                title: this.props.sliderData.Title,
                caption: this.props.sliderData.Caption,
                image: this.props.sliderData.ImagePath,
                imagePosition: this.props.sliderData.ImagePosition,
                btnName: this.props.sliderData.BtnName,
                btnLink: this.props.sliderData.BtnLink,
                photo: this.props.sliderData.ImagePath,
                ImagePath: this.props.sliderData.ImagePath,
            },
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onUpdateSlider();
    };

    async onUpdateSlider() {
        let sendData = {
            ...this.state.formData,
            submittedBy: "E1792"
        };

        if (this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter slider title", "error")
            return false;
        }

        // if(this.state.formData.caption === "") {
        //     this.setState({
        //         isLoading: false,
        //     });
        //     this.showAlert("FORM VALIDATION ERROR", "Please select slider caption", "error")
        //     return false;
        // }
        if (this.state.formData.btnName === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select button name", "error")
            return false;
        }
        if (this.state.formData.btnLink === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select button link", "error")
            return false;
        }

        if (this.state.formData.imagePosition === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter image position", "error")
            return false;
        }

        axios.patch(`${serverLink}slider/update`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success") {

                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.formData.id)
                        axios.patch(`${serverLink}slider/updateSlidersPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                                const res = result.data.lastID;
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showAlert("SUCCESS", "NEWS ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the news details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             ImagePath: url,
    //         },
    //     });
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 5000000) {
                this.showAlert("Oops!", "max file size is 5mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };

    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '#/general/slider/sliderslist';
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    Slider
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    Update Slider
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>UPDATE SLIDER</h3>
                                    </div>
                                </div>
                                <SliderForm
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    onImageChange={this.onImageChange}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    image={this.state.htmlelement}
                                />
                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sliderData: state.generalDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateSlider);
