import React, { Component } from "react";
import { Link } from "react-router-dom";
import bazeLogo from "../../images/logobaze.png";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { setFacultyDetails } from "../../actions/facultyactions";
import { connect } from "react-redux";
import { sidebarAccessControl } from "../../permission/permission";
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userPermission: this.props.loginDetails.IsAdmin,
      facultyList: [],
    };
  }
  componentDidMount() {
    this.fetchFaculty();
  }

  async fetchFaculty() {
    await axios.get(`${serverLink}faculty/list/academics`).then((data) => {
      const result = data.data;
      this.setState({
        facultyList: [...result],
      });
    });

    this.setState({ isLoading: false });
  }
  render() {
    return (
      // <!--begin::Aside-->
      <div
        id="kt_aside"
        className="aside aside-dark aside-hoverable"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      >
        {/* <!--begin::Brand--> */}
        <div className="aside-logo flex-column-auto" id="kt_aside_logo">
          {/* <!--begin::Logo--> */}
          <a href="#/dashboard">
            <img
              alt="Logo"
              src={bazeLogo}
              className="img-thumbnail"
              style={{ backgroundColor: "#eeeeee", height: "50px" }}
            />
          </a>
          {/* <!--end::Logo--> */}
          {/* <!--begin::Aside toggler--> */}
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg--> */}
            <span className="svg-icon svg-icon-1 rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                  fill="black"
                />
                <path
                  d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                  fill="black"
                />
              </svg>
            </span>
            {/* <!--end::Svg Icon--> */}
          </div>
          {/* <!--end::Aside toggler--> */}
        </div>
        {/* <!--end::Brand--> */}
        {/* <!--begin::Aside menu--> */}

        <div className="aside-menu flex-column-fluid">
          {/* <!--begin::Aside Menu--> */}
          <div
            className="hover-scroll-overlay-y my-5 my-lg-5"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset="0"
          >
            {/* <!--begin::Menu--> */}
            <div
              className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
              id="#kt_aside_menu"
              data-kt-menu="true"
            >
              <div className="menu-item">
                <div className="menu-content pb-2">
                  <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                    Dashboard
                  </span>
                </div>
              </div>
              <div className="menu-item">
                <Link to="/dashboard" className="menu-link active">
                  <span className="menu-icon">
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
                    <span className="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          x="2"
                          y="2"
                          width="9"
                          height="9"
                          rx="2"
                          fill="black"
                        />
                        <rect
                          opacity="0.3"
                          x="13"
                          y="2"
                          width="9"
                          height="9"
                          rx="2"
                          fill="black"
                        />
                        <rect
                          opacity="0.3"
                          x="13"
                          y="13"
                          width="9"
                          height="9"
                          rx="2"
                          fill="black"
                        />
                        <rect
                          opacity="0.3"
                          x="2"
                          y="13"
                          width="9"
                          height="9"
                          rx="2"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {/* <!--end::Svg Icon--> */}
                  </span>
                  <span className="menu-title">Dashboard</span>
                </Link>
              </div>
              {sidebarAccessControl([1, 4], this.state.userPermission) ? (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-comment-alt text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">About</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      <span className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Gallery</span>
                        <span className="menu-arrow" />
                      </span>
                      <div className="menu-sub menu-sub-accordion menu-active-bg">
                        <div className="menu-item">
                          <Link
                            to="/about/gallery/addphoto"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">Add gallery</span>
                          </Link>
                        </div>

                        <div className="menu-item">
                          <Link to="/about/gallery/list" className="menu-link">
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title"> Gallery list </span>
                          </Link>
                        </div>

                        <div className="menu-item">
                          <Link
                            to="/about/gallery/addcategory"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">Add category</span>
                          </Link>
                        </div>

                        <div className="menu-item">
                          <Link
                            to="/about/gallery/addsubcategory"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">Add subcategory</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {sidebarAccessControl([1, 4], this.state.userPermission) ? (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-calendar-check text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">Event</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div className="menu-item">
                      <Link to="/event/general/add" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Add Event</span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link to="/event/general/list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Event List</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {sidebarAccessControl([1, 4], this.state.userPermission) ? (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-newspaper text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">News</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div className="menu-item">
                      <Link to="/news/general/add" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Add News</span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link to="/news/general/bazemedia" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title"> Baze in Media</span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link to="/news/general/news/list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">News List</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {sidebarAccessControl([1, 2, 3, 4], this.state.userPermission) ? (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-university text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">Faculty</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <a href={`#/faculty/list`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot" />
                      </span>
                      <span className="menu-title">Faculty List</span>
                    </a>
                    {/* {
                                                this.state.facultyList.length === 0 ? (
                                                    <div>No Faculty</div>
                                                ) : this.state.facultyList &&
                                                this.state.facultyList.map((fac, k) => {
                                                    return (
                                                        <div
                                                            key={k}
                                                            onClick={async () => {
                                                                const facultyData = { ...fac }
                                                                this.props.setOnFacultyDetails(facultyData);
                                                            }} className="menu-item" style={{ textAlign: 'left' }}>
                                                            <a href={`#/faculty/dashboard/${fac.Slug}`} className="menu-link">
                                                                <span className="menu-bullet">
                                                                    <span className="bullet bullet-dot" />
                                                                </span>
                                                                <span className="menu-title">{fac.FacultyName}</span>
                                                            </a>
                                                        </div>
                                                    )
                                                })

                                            } */}
                  </div>
                </div>
              ) : (
                ""
              )}

              {sidebarAccessControl([1, 4], this.state.userPermission) ? (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-user-graduate text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">Alumni</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div className="menu-item">
                      <Link to="/alumni/list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Alumni List</span>
                      </Link>
                    </div>

                    <div className="menu-item">
                      <Link to="/alumni/feedback/list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">
                          {" "}
                          Alumni Feedback List
                        </span>
                      </Link>
                    </div>

                    {/* <!--end::Menu--> */}
                  </div>
                  {/* <!--end::Aside Menu--> */}
                </div>
              ) : (
                ""
              )}

              {sidebarAccessControl([1, 4], this.state.userPermission) ? (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-house-user text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">Leadership</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div className="menu-item">
                      <Link
                        to="/leadership/addleadership"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">
                          Add Leadership (Board)
                        </span>
                      </Link>
                    </div>
                    {/* <div className="menu-item">
                                                <Link to="/leadership/leadershiplist" className="menu-link">
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot" />
                                                    </span>
                                                    <span className="menu-title">Leadership List (All)</span>
                                                </Link>
                                            </div> */}
                    <div className="menu-item">
                      <Link to="/leadership/boardlist" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Board List</span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link
                        to="/leadership/managementlist"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Management List</span>
                      </Link>
                    </div>
                    <div className="menu-item">
                      <Link to="/leadership/senatelist" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Senate List</span>
                      </Link>
                    </div>

                    <div className="menu-item">
                      <Link to="/leadership/list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Staff List</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {sidebarAccessControl([1], this.state.userPermission) ? (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-exchange-alt text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">General</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      <span className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Website Headline</span>
                        <span className="menu-arrow" />
                      </span>
                      <div className="menu-sub menu-sub-accordion menu-active-bg">
                        <div className="menu-item">
                          <Link
                            to="/general/headline/addheadline"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">Add Headline</span>
                          </Link>
                        </div>
                      </div>
                      <div className="menu-sub menu-sub-accordion menu-active-bg">
                        <div className="menu-item">
                          <Link
                            to="/general/headline/headlinelist"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title"> Headline List</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <!--end::Menu--> */}
                  </div>
                  {/* <!--end::Aside Menu--> */}
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      <span className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Home Slider</span>
                        <span className="menu-arrow" />
                      </span>
                      <div className="menu-sub menu-sub-accordion menu-active-bg">
                        <div className="menu-item">
                          <Link
                            to="/general/slider/addslider"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">Add Slider</span>
                          </Link>
                        </div>
                      </div>
                      <div className="menu-sub menu-sub-accordion menu-active-bg">
                        <div className="menu-item">
                          <Link
                            to="/general/slider/sliderslist"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title"> Sliders List</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <!--end::Menu--> */}
                  </div>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      <span className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot" />
                        </span>
                        <span className="menu-title">Academic Calender</span>
                        <span className="menu-arrow" />
                      </span>
                      <div className="menu-sub menu-sub-accordion menu-active-bg">
                        <div className="menu-item">
                          <Link
                            to="/general/academic-calender"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">
                              Add Academic Calender
                            </span>
                          </Link>
                        </div>
                        <div className="menu-item">
                          <Link
                            to="/general/academic-calender-view"
                            className="menu-link"
                          >
                            <span className="menu-bullet">
                              <span className="bullet bullet-dot" />
                            </span>
                            <span className="menu-title">
                              Academic Calender List
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <!--end::Menu--> */}
                  </div>
                </div>
              ) : (
                ""
              )}

              {sidebarAccessControl([1, 2], this.state.userPermission) ? (
                <div className="menu-item">
                  <Link to="/permission" className="menu-link active">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
                      <span className="fa fa-cog text-primary " />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">Permission</span>
                  </Link>
                </div>
              ) : (
                ""
              )}

              <div className="menu-item">
                <Link to="/feedback" className="menu-link active">
                  <span className="menu-icon">
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
                    <span className="fa fa-cog text-primary " />
                    {/* <!--end::Svg Icon--> */}
                  </span>
                  <span className="menu-title">Website Feedback</span>
                </Link>
              </div>
              {sidebarAccessControl([1, 4], this.state.userPermission) && (
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg--> */}
                      <span className="fa fa-comment-alt text-primary" />
                      {/* <!--end::Svg Icon--> */}
                    </span>
                    <span className="menu-title">Analysis</span>
                    <span className="menu-arrow" />
                  </span>
                  <div className="menu-sub menu-sub-accordion menu-active-bg">
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      <div className="menu-item">
                        <Link to="/analysis/moduleReport" className="menu-link">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title"> Module Report </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <br />
              <br />
              <div className="menu-item">
                <Link to="/logout" className="menu-link active">
                  <span className="menu-icon">
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
                    <span className="fa fa-lock text-primary " />
                    {/* <!--end::Svg Icon--> */}
                  </span>
                  <span className="menu-title">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOnFacultyDetails: (p) => {
      dispatch(setFacultyDetails(p));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
