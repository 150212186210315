import React, { Component } from "react";
import LeadershipForm from "./leadershipform";
import axios from "axios";
import { serverLink } from "../../resources/url";
import swal from "sweetalert";
import Header from "../Header/header";
import { connect } from "react-redux";


class AddLeadership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: "",
            formData: {
                fullname: "",
                position: "",
                designation: "",
                image: "",
                title: "",
                status: "",
                description: "",
                photo: ""
            },
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddLeadership();
    };

    componentDidMount(){
    }
    

    async onAddLeadership() {
        let sendData = {
            ...this.state.formData,
            submittedBy: this.props.loginDetails.staff_name,
            category: "Board"
        };

        if (this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter title", "error")
            return false;
        }

        if (this.state.formData.fullname === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter Full Name", "error")
            return false;
        }

        if (this.state.formData.position === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select position", "error")
            return false;
        }
        if (this.state.formData.designation === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter designation", "error")
            return false;
        }
        if (this.state.formData.status === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select status", "error")
            return false;
        }

        if (this.state.formData.description === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter description", "error")
            return false;
        }

        axios.post(`${serverLink}leadership/add_leadership`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "name exist") {
                    this.showAlert("Oops!", "Leadership with the same name and title already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the leadership. Please try again!", "error")
                    return false;
                } else if (response === "success") {

                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', result.data.entry_id)
                        axios.post(`${serverLink}leadership/uploadLeadershipsPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showAlert("SUCCESS", "LEADERSHIP ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the leadership details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             ImagePath: url,
    //         },
    //     });
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };


    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '#/leadership/list';
        });
    }
    render() {
        return (
            <>
                <Header isFaculty={false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Leadership
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Add Leadership (Board)
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>ADD LEADERSHIP (BOARD)</h3>
                                    </div>
                                </div>
                                <LeadershipForm
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    onImageChange={this.onImageChange}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    image={this.state.htmlelement}
                                />

                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};


export default connect(mapStateToProps, null)(AddLeadership);

