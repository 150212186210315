export const facultyDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_FACULTY_DETAILS":
            return action.payload;

        default:
            return state;
    }
};

export const facultyDataReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_FACULTY_DATA":
            return action.payload;
        default:
            return state;
    }
};

export const courseDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_COURSE_DETAILS":
            return action.payload;
        default:
            return state;
    }
};

export const facultyNavbarReducer = (state = "", action) => {
    switch (action.type) {
        case "SET_FACULTYNAVBAR_DETAILS":
            return action.payload;

        default:
            return state;
    }
};