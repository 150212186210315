import React, {Component} from "react";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import swal from "sweetalert";
import Headlineform from "./headlineform";
import Header from "../../Header/header";
import {connect} from "react-redux";
class AddHeadline extends Component {
	constructor(props) {
		super(props);
		this.state ={
			isLoading: "off",
			htmlelement: <i className="fa fa-user fa-8x" aria-hidden="true"/>,
			formData: {
				linkTitle: "",
				linkPath: "",
				bgColour: "",
				messageMsg: "",
			},
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({
			isLoading: "on",
		});
		this.onAddHeadline();
	};

	async onAddHeadline() {
		let sendData = {
			...this.state.formData,
			submittedBy: this.props.loginDetails.staff_name,
		};

		if(this.state.formData.linkTitle === "") {
			this.setState({
				isLoading: false,
			});
			this.showAlert("FORM VALIDATION ERROR", "Please enter headline title", "error")
			return false;
		}

		if(this.state.formData.linkPath === "") {
			this.setState({
				isLoading: false,
			});
			this.showAlert("FORM VALIDATION ERROR", "Please enter Link Path caption", "error")
			return false;
		}
		if(this.state.formData.bgColour === "") {
			this.setState({
				isLoading: false,
			});
			this.showAlert("FORM VALIDATION ERROR", "Please select Background Color ", "error")
			return false;
		}
		if(this.state.formData.messageMsg === "") {
			this.setState({
				isLoading: false,
			});
			this.showAlert("FORM VALIDATION ERROR", "Please enter Message", "error")
			return false;
		}


		axios.post(`${serverLink}news/add_headline`, sendData)
			.then(result => {
				this.setState({
					isLoading: false,
				});
				const response = result.data.message;
				if(response === "Headline exist") {
					this.showAlert("Oops!", "Headline with the same title already exist", "error")
					return false;
				} else if (response === "failed") {
					this.showAlert("Oops!", "Something went wrong adding the headline. Please try again!", "error")
					return false;
				} else if (response === "success"){
					this.showAlert("SUCCESS", "SLIDER ADDED SUCCESSFULLY", "success")
					return false;
				} else {
					this.showAlert("Oops!", "Failed to add the headline details!", "error")
					return false;
				}

			})
			.catch(err => {
				this.setState({
					isLoading: false,
				});
				console.error('ERROR', err);
			});
	}

	onEdit = (e) => {
		const formData = this.state.formData;
		this.setState({
			formData: {
				...formData,
				[e.target.name]: e.target.value,
			},
		});
	};

	showAlert(title, msg, type){
		return swal({
			title: title,
			text: msg,
			icon: type,
			button: "OK",
		}).then((value) => {
			window.location.href = '#/general/headline/headlinelist';
		});
	}

    render() {
        return (
			<>
				<Header isFaculty={false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
							<h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
								<span className="h-20px border-dark  border-start ms-3 mx-2"/>
								Headline
								<span className="h-20px border-dark  border-start ms-3 mx-2"/>
								Add Headline
							</h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
									<div className="card-header">
										<div className="card-title">
											<h3>ADD HEADLINE</h3>
										</div>
									</div>
										<Headlineform
											handleSubmit={this.handleSubmit}
											onEdit={this.onEdit}
											isLoading={this.state.isLoading}
											data={this.state.formData}
										/>
								</div>
                        {/* CONTENT END */}
                 </div>
            </div>
            </div>
				</>
        )
    }
}



const mapStateToProps = (state) => {
	return {
		loginDetails: state.loginDetails,
	};
};
export default connect(mapStateToProps, null)(AddHeadline);