import React, {Component} from "react";
import { connect } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink} from "../../../resources/url";
import PhotoForm from "./photoform";
import Header from "../../Header/header";
import {accessControl} from "../../../permission/permission";


class UpdatePhoto extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 4]);
        this.state = {
            isLoading: false,
            imagefile: "",
            htmlelement: `${serverLink}public/uploads/gallery/${this.props.galleryData.ImagePath}`,
            formData: {
                id: this.props.galleryData.EntryID,
                ImageTitle: this.props.galleryData.ImageTitle,
                SubCategoryID: this.props.galleryData.SubCategoryID,
                photo: this.props.galleryData.ImagePath,
                ImagePath: this.props.galleryData.ImagePath,
            },
            categoryList: [],
            subCategoryList: [],
        }
    }

    componentDidMount() {
        this.fetchGalleryCategoryList();
    }

    async fetchGalleryCategoryList() {
        await fetch(`${serverLink}gallery/category/list`)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    categoryList: data,
                    isLoading: false,
                });
            });
    }

    async fetchGallerySubCategoryList(categoryID) {
        await fetch(`${serverLink}gallery/subcategory/list/${categoryID}`)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    subCategoryList: data,
                    isLoading: false,
                });
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onGlleryUpdate();
    };

    async onGlleryUpdate() {
        let sendData = {
            ...this.state.formData,
            InsertedBy:  this.props.loginDetails.staff_name,
        };

        if(this.state.formData.category === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select category", "error")
            return false;
        }

        if(this.state.formData.SubCategoryID === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select sub category", "error")
            return false;
        }

        if(this.state.formData.ImageTitle === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter image title", "error")
            return false;
        }


        axios.patch(`${serverLink}gallery/update`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){

                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.formData.id)
                        axios.patch(`${serverLink}gallery/uploadGalleryPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });

                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showSuccessAlert("SUCCESS", "GALLERY UPDATED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the gallery details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });

        if(e.target.name === 'category') {
            let index = e.nativeEvent.target.selectedIndex;
            const subSubCategoryID = e.nativeEvent.target[index].getAttribute('data');
            this.fetchGallerySubCategoryList(subSubCategoryID);

            this.setState({
                formData: {
                    ...formData,
                    [e.target.name]: e.target.value,
                },
            });

        }

    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             ImagePath: url,
    //             InsertedBy: this.props.loginDetails.staff_name,
    //         },
    //     });
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 5000000) {
                this.showAlert("Oops!", "max file size is 5mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };


    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '#/about/gallery/list';
        });
    }

    render() {
        return (
            <><Header isFaculty={false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">About
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                Gallery
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                Update
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3>UPDATE GALLERY</h3>
                                </div>
                            </div>
                            <PhotoForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                isLoading={this.state.isLoading}
                                data={this.state.formData}
                                categoryList={this.state.categoryList}
                                subCategoryList={this.state.subCategoryList}
                                onImageChange={this.onImageChange}
                                image={this.state.htmlelement}
                            />
                        </div>
                        {/* CONTENT END */}
                    </div>
                </div>
            </div>
                </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        galleryData: state.generalDetails,
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdatePhoto);
