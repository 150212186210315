import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { formatDate, serverLink, showAlert } from "../../../resources/url";
import Header from "../../Header/header";
import SliderForm from "../slider/sliderform";


const AddAcademicCalender = (props) => {
    const navigate = useNavigate()
    const slug = window.location.href.split("=")[1];
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        Description: "",
        EntryID: "",
        SchoolSemester: "",
        StartDate: "",
        DateLine: "",
        InsertedBy: props.loginDetails.staff_id,
    })

    const getData = (id) => {
        axios.get(`${serverLink}resources/academic/calender-s/${id}`)
            .then(res => {
                //console.log(res.data)
                if (res.data.length > 0) {
                    const dt = res.data[0]
                    setFormData({
                        ...formData,
                        Description: dt.Description,
                        EntryID: dt.EntryID,
                        SchoolSemester: dt.SchoolSemester,
                        StartDate: formatDate(dt.StartDate),
                        DateLine: formatDate(dt.DateLine),
                    })
                }
            })
            .catch(error => {
                console.log("ERROR FETCHING THE ACADEMIC CALENDER")
            });

    }

    useEffect(() => {
        //console.log(slug)
        getData(slug !== undefined ? slug : '');
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.EntryID === "") {
            await axios.post(`${serverLink}resources/add-academic-calender/`, formData).then((res) => {
                if (res.data.message === "success") {
                    showAlert("Success", "Academic Calender Added", "success").then(() => {
                        navigate('/general/academic-calender-view')
                    })
                } else {
                    showAlert("warning", "Academic Calender already exists", "warning")
                }
            })
        } else {
            await axios.patch(`${serverLink}resources/update-academic-calender/`, formData).then((res) => {
                if (res.data.message === "success") {
                    showAlert("Success", "Academic Calender Updated", "success").then(() => {
                        navigate('/general/academic-calender-view')
                    })
                }
            })
        }


    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    return (
        <div>
            <Header isFaculty={false} />
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
                                <span className="h-20px border-dark  border-start ms-3 mx-2"></span>
                                Academic Calender
                                <span className="h-20px border-dark  border-start ms-3 mx-2"></span>
                                Add Academic Calender
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3>ACADEMI CALENDER</h3>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body" style={{ textAlign: 'left' }}>
                                    <div className="me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                        <div className="row mb-5">
                                            <div className="col-md-12 fv-row">
                                                <label className="required fs-5 fw-bold mb-2">School Semester</label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid  mb-5"
                                                    name="SchoolSemester"
                                                    id="SchoolSemester"
                                                    required
                                                    onChange={onEdit}
                                                    value={formData.SchoolSemester}
                                                    placeholder="semester e.g 22A"
                                                />
                                            </div>
                                            <div className="col-md-6 fv-row">
                                                <label className="required fs-5 fw-bold mb-2">Start Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-solid  mb-5"
                                                    name="StartDate"
                                                    required
                                                    id="StartDate"
                                                    onChange={onEdit}
                                                    value={formData.StartDate}
                                                />
                                            </div>
                                            <div className="col-md-6 fv-row">
                                                <label className="required fs-5 fw-bold mb-2">End Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-solid  mb-5"
                                                    name="DateLine"
                                                    required
                                                    id="DateLine"
                                                    onChange={onEdit}
                                                    value={formData.DateLine}
                                                />
                                            </div>

                                            <div className="col-md-12 fv-row">
                                                <label className="required fs-5 fw-bold mb-2">Description</label>
                                                <textarea
                                                    type="text"
                                                    required
                                                    className="form-control form-control-solid  mb-5"
                                                    name="Description"
                                                    id="Description"
                                                    onChange={onEdit}
                                                    rows={5} value={formData.Description}
                                                ></textarea>
                                            </div>
                                        </div>


                                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                                            <span className="indicator-label">Submit</span>
                                            <span className="indicator-progress">Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* CONTENT END */}
                    </div>
                </div>
            </div>

        </div>

    )
}
const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AddAcademicCalender);

