import React, { Component } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import SimpleFileUpload from "react-simple-file-upload";
import { simpleFileUploadAPIKey, simpleFileUploadFolderName } from "../../resources/url";

class Newsform extends Component {
    constructor(props) {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.content);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ''),
        };
    }

    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        });
        this.props.data.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    render() {
        const { editorState } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">News Title</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="title"
                                    id="title"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.title}
                                    placeholder="News Title"

                                />
                            </div>

                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Banner Image</label>
                                {/*<SimpleFileUpload*/}
                                {/*    apiKey={simpleFileUploadAPIKey}*/}
                                {/*    maxFileSize={1}*/}
                                {/*    tag={simpleFileUploadFolderName + `-news`}*/}
                                {/*    onSuccess={this.props.onImageChange}*/}
                                {/*    preview="false"*/}
                                {/*    width="100%"*/}
                                {/*    height="100"*/}
                                {/*/>*/}
                                <div className="mb-3">
                                    <div className="fv-row mb-2">
                                        <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={() => {
                                            document.getElementById("photo").click()
                                        }}>
                                            <div className="dz-message needsclick">
                                                <i className="bi bi-image text-primary fs-3x" />
                                                <div className="ms-4 col-md-9">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                    <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                </div>
                                                <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={120} height={100} srcSet={this.props.image !== "" ? this.props.image : ""} /> : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="alert-danger"> Max of 2MB file is allowed!</span>
                                </div>
                                <input type="file" id="photo" name={"photo"} accept={"image/*"} onChange={this.props.onImageChange} hidden />
                            </div>

                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Status</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="Status"
                                    id="Status"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.Status}
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Publish</option>
                                    <option value="0">Draft</option>
                                </select>
                            </div>

                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Author </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    className="form-control form-control-solid  mb-5"
                                    name="submittedBy"
                                    id="submittedBy"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.submittedBy}
                                    placeholder="Author"
                                    required
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-column mb-9 fv-row">
                            <label className="required fs-5 fw-bold mb-2">News Content</label>
                            <Editor
                                className="form-control form-control-solid"
                                rows={4}
                                name="content"
                                id="content"
                                onEditorStateChange={this.onEditorStateChange}
                                wrapperClassName="form-control form-control-solid"
                                editorClassName="form-control form-control-solid"
                                editorState={editorState}
                            />

                        </div>

                        <div className="row">
                            <div className="col-md-8 ">
                                <h2 className="text-center">Add Multiple Images Here</h2>
                                <label className="required fs-5 fw-bold mb-2">News Gallery </label>
                                <input type="file" id="news_gallery" name={"news_gallery"} accept={"image/*"} className="form-control" onChange={this.props.MultiImageChange} />
                            </div>
                            <div className="col-md-4 ">
                                <div className="table-responsive">
                                    <table className="table gs-7 table-bordered table-striped">
                                        <thead>
                                            <tr class="fw-bold fs-6 text-gray-800">
                                                <th>News Gallery</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.multiImges.length > 0 &&
                                                this.props.multiImges.map((x, i) => {
                                                    return (
                                                        <tr key={i} className="p-5">
                                                            <td>{x.file.name}</td>
                                                            <td>
                                                                <img width={"50px"} src={x.preview} className="img-thumbnail" />
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-sm btn-danger" onClick={(e) => { this.props.handleRemove(e, x) }} >
                                                                    <i className="fa fa-trash" />
                                                                </button>
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>





                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default Newsform;