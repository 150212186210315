import React, {Component} from "react";
import { connect } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink} from "../../resources/url";
import LeadershipForm from "./leadershipform";
import Header from "../Header/header";

class EnrolManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            htmlelement: <i className="fa fa-user fa-8x" aria-hidden="true"/>,
            formData: {
                id: this.props.leadershipData.EntryID,
                title: this.props.leadershipData.Title,
                fullname: this.props.leadershipData.staff_name,
                designation: this.props.leadershipData.designation,
                photo: this.props.leadershipData.ImagePath,
                position: this.props.leadershipData.Position,
                status: this.props.leadershipData.Status,
                description: this.props.leadershipData.Description === undefined ? "" : this.props.leadershipData.Description,
                image: this.props.leadershipData.ImagePath,
                ImagePath : this.props.leadershipData.ImagePath,

            },
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onEnrolManagement();
    };

    async onEnrolManagement() {
        let sendData = {
            ...this.state.formData,
            submittedBy: this.props.loginDetails.staff_name,
            category: "Management"
        };

        if(this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select title", "error")
            return false;
        }

        if(this.state.formData.fullname === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter Full Name", "error")
            return false;
        }

        if(this.state.formData.position === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please  enter position", "error")
            return false;
        }
        if(this.state.formData.designation === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please  enter designation", "error")
            return false;
        }
        if(this.state.formData.status === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select status", "error")
            return false;
        }

        if(this.state.formData.description === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter description", "error")
            return false;
        }


        axios.post(`${serverLink}leadership/enrol_management`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "name exist") {
                    this.showAlert("Oops!", "Management with the same name and title already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the management. Please try again!", "error")
                    return false;
                } else if (response === "success"){
                    // if (this.state.imagefile !== "") {
                    //     const formData = new FormData();
                    //     formData.append('photo', this.state.imagefile);
                    //     formData.append('entry_id', result.data.entry_id)
                    //     axios.post(`${serverLink}leadership/uploadPhoto`, formData)
                    //         .then(result => {
                    //             this.setState({
                    //                 isLoading: false,
                    //             });
                    //             const res = result.data.lastID;
                    //         })
                    //         .catch(err => {
                    //             this.setState({
                    //                 isLoading: false,
                    //             });
                    //             console.error('ERROR', err);
                    //         });
                    // }
                    this.showAlert("SUCCESS", "MANAGEMENT ENROLLED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to enrolled management details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onImageChange = (url) => {
        const formData = this.state.formData;

        this.setState({
            formData: {
                ...formData,
                ImagePath: url,
            },
        });
        // if (event.target.files && event.target.files[0]) {
        //     this.setState({
        //         imagefile: event.target.files[0],
        //     });

        //     let reader = new FileReader();
        //     reader.onload = (e) => {
        //         this.setState({
        //             htmlelement: (
        //                 <div className="addpersonpage">
        //                     <img
        //                         className="netimage"
        //                         srcSet={e.target.result}
        //                         alt="profileImage"
        //                     />
        //                 </div>
        //             ),
        //         });
        //     };
        //     reader.readAsDataURL(event.target.files[0]);
        //     this.setState({});
        // }
    };


    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '#/leadership/list';
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Leadership
                                <span className="h-20px border-dark  border-start ms-3 mx-2"></span>
                                Enrol Management
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3>ENROL MANAGEMENT </h3>
                                </div>
                            </div>
                            <LeadershipForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                onImageChange={this.onImageChange}
                                isLoading={this.state.isLoading}
                                data={this.state.formData}
                            />
                        </div>
                        {/* CONTENT END */}
                    </div>
                </div>
            </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        leadershipData: state.generalDetails,
        loginDetails: state.loginDetails
    };
};
export default connect(mapStateToProps, null)(EnrolManagement);
