import React, {Component} from "react";
import {connect} from "react-redux";
import Header from "../../Header/header";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import {Oval} from "react-loader-spinner";
import { setgeneralDetails } from "../../../actions/setgeneraldetailsaction";
import CourseContentPage from "./addaboutcourse";

class LoadCourseData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            CourseID: this.props.courseData.CourseID,
        }
    }
    componentDidMount() {
        this.fetchCourseDetails();
    }

    async fetchCourseDetails() {
        await axios.get(`${serverLink}course/list/all/${this.state.CourseID}`)
            .then(data => {
                const result = data.data;
                if (result.length !== 0) {
                    this.props.setOnGeneralData(result)

                } else {
                    this.props.setOnGeneralData([])
                }

            });
        this.setState({ isLoading: false })

    }

    render() {
        return (
            <>
                <Header isFaculty={true}/>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    {/* <!--begin::Toolbar--> */}
                    <div className="toolbar" id="kt_toolbar">
                        {/* <!--begin::Container--> */}
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            {/* <!--begin::Page title--> */}
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                {/* <!--begin::Title--> */}
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Dashboard
                                    {/* <!--begin::Separator--> */}
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2"/>
                                    {/* <!--end::Separator--> */}
                                </h1>
                                {/* <!--end::Title--> */}
                            </div>
                            {/* <!--end::Page title--> */}
                            {/* <!--begin::Actions--> */}
                            {/* <!--end::Actions--> */}
                        </div>
                        {/* <!--end::Container--> */}
                    </div>
                    {/* <!--end::Toolbar--> */}
                    {/* <!--begin::Post--> */}
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        {/* <!--begin::Container--> */}
                        <div id="kt_content_container" className="container-fluid">
                            {/* <!--begin::Row--> */}
                            {this.state.isLoading ?
                                <div style={{
                                    margin: 'auto',
                                    position: 'relative',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: '400px'
                                }}>
                                    <Oval
                                        type="Oval"
                                        color="#eeeeee"
                                        height={50}
                                        width={50}
                                        secondaryColor="teal"
                                        timeout={15000} //3 secs
                                    />
                                </div>
                                :
                                <CourseContentPage/>
                            }
                            {/* <!--end::Container--> */}
                        </div>
                        {/* <!--end::Post--> */}
                    </div>
                    {/* <!--end::Content--> */}
                </div>
            </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralData: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        courseData: state.courseDetails,
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(LoadCourseData);