import React, { Component } from "react";
import axios from "axios";
import { checkImageURL, serverLink } from "../../../resources/url";
import swal from "sweetalert";
import Header from "../../Header/header";
import bbanner from "../../../images/compter.jpg";
import { connect } from "react-redux";
import { setgeneralDetails } from "../../../actions/setgeneraldetailsaction";
import AboutCourseForm from "./aboutcourseform";
import { accessControl } from "../../../permission/permission";
import DefBanner from './def_banner.jpg'


class AddAboutCourse extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 2, 3, 4]);
        this.state = {
            isLoading: true,
            imagefile: "",
            htmlelement: "",
            staff_list : [],
            formData: {
                id: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].EntryID,
                courseCode: this.props.courseData.CourseCode,
                courseID: this.props.courseData.CourseID,
                courseName: this.props.courseData.Description,
                slug: this.props.courseData.Slug.replace(/[/]/g, "-"),
                content: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].Description,
                photo: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].ImagePath,
                CourseHOD: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].CourseHOD,
                HODContent: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].HODContent,
                ImagePath: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].ImagePath,
                HODImage: '',
                HODImageFile: ""
            },
        }
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddAboutDepartment();
    };



    async onAddAboutDepartment() {
        let sendData = {
            ...this.state.formData,
            EntryID: this.state.formData.EntryID,
            courseID: this.props.courseData.CourseID,            //EntryID : this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].EntryID,
            submittedBy: this.props.loginDetails.staff_name,
            updatedBy: this.props.loginDetails.staff_name,
            id: this.state.formData.EntryID,
            //id: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].EntryID,
            courseCode: this.props.courseData.CourseCode,
            courseName: this.props.courseData.Description,
            slug: this.state.formData.slug,
            content: this.state.formData.content,
            photo: this.state.formData.photo,
            CourseHOD: this.state.formData.CourseHOD,
            HODContent: this.state.formData.HODContent,
            ImagePath: this.state.formData.ImagePath,
        };

        if (this.state.formData.ImagePath === "" || this.state.formData.ImagePath === null) {
            this.showAlert("Error", "Please select a banner image", "error")
            return false
        }
        if (this.state.formData.HODImage === "" || this.state.formData.HODImage === null) {
            this.showAlert("Error", "Please select a HOD image", "error")
            return false
        }

        axios.post(`${serverLink}course/about`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the about department. Please try again!", "error")
                    return false;
                } else if (response === "success") {
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.formData.id === "" ? result.data.entry_id : this.state.formData.id)
                        axios.patch(`${serverLink}course/uploadCoursePhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                console.log(err)
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });

                    }
                    if (this.state.formData.HODImageFile !== "") {
                        const formData = new FormData();
                        formData.append("HODImage", this.state.formData.HODImageFile)
                        formData.append('entry_id', this.state.formData.id === "" ? result.data.entry_id : this.state.formData.id)
                        axios.patch(`${serverLink}course/uploadCourseHod`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showAlert("SUCCESS", "ABOUT COURSE ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the about course details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    componentDidMount() {
        this.fetchCourseDetails();
    }



    async fetchCourseDetails() {
        await axios.get(`${serverLink}course/content/by/${this.props.courseData.CourseID}`)
            .then(data => {
                const result = data.data;
                if (result.length > 0) {
                    const formData = this.state.formData;
                    this.setState({
                        formData: {
                            ...formData,
                            id: result[0].EntryID,
                            EntryID: result[0].EntryID,
                            content: result[0].Description,
                            photo: result[0].ImagePath,
                            CourseHOD: result[0].CourseHOD,
                            HODContent: result[0].HODContent,
                            ImagePath: result[0].ImagePath,
                            HODImage: result[0].HODImage
                        },
                    });
                }

            });

        await axios.get(`${serverLink}staff/list/active`)
            .then(data => {
                const result = data.data;
                this.setState({
                    staff_list: [...result]

                });
            });
    }


    onEdit = (e) => {

        const formData = this.state.formData;
        if (e.target.id === "HODImageFile") {
            const file = e.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            this.setState({
                formData: {
                    ...formData,
                    HODImageFile: e.target.files[0],
                    HODImage: URL.createObjectURL(e.target.files[0])
                },

            });

        } else {
            this.setState({
                formData: {
                    ...formData,
                    [e.target.name]: e.target.value,
                },
            });
        }
    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             ImagePath: url,
    //         },
    //     });
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            const formData = this.state.formData;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                    formData: {
                        ...formData,
                        ImagePath: URL.createObjectURL(event.target.files[0])
                    },
                });
            };
        }
    };

    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        })
    }

    ImgeUrl = (url) => {
        if (url === null || url === "") {
            return DefBanner;
        } else if (url.includes("simplefileupload")) {
            return checkImageURL(url) ? url : DefBanner
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/course_uploads/${url}`) ? `${serverLink}public/uploads/course_uploads/${url}` : DefBanner} `
        }
    }


    render() {
        return (
            <>
                <Header isFaculty={true} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    {/* <!--begin::Toolbar--> */}
                    <div className="toolbar" id="kt_toolbar">
                        {/* <!--begin::Container--> */}
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            {/* <!--begin::Page title--> */}
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                {/* <!--begin::Title--> */}
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Dashboard
                                    {/* <!--begin::Separator--> */}
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                                    {/* <!--end::Separator--> */}
                                </h1>
                                {/* <!--end::Title--> */}
                            </div>
                            {/* <!--end::Page title--> */}
                            {/* <!--begin::Actions--> */}
                            {/* <!--end::Actions--> */}
                        </div>
                        {/* <!--end::Container--> */}
                    </div>
                    {/* <!--end::Toolbar--> */}
                    {/* <!--begin::Post--> */}
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        {/* <!--begin::Container--> */}
                        <div id="kt_content_container" className="container-fluid">
                            {/* <!--begin::Row--> */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-body py-10">
                                    <h2 className="mb-5 fw-bolder text-uppercase bi-pin-angle-fill " style={{ textAlign: 'left' }}> {this.props.courseData.Description}</h2>
                                    <div className="" style={{ height: '400px' }}>
                                        {
                                            this.state.htmlelement === "" ?

                                                this.state.formData.photo !== "" || this.state.formData.photo === null ?
                                                    <img src={this.ImgeUrl(this.state.formData.photo)} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />
                                                    // <img src={`${serverLink}public/uploads/course_uploads/${this.state.formData.photo}`} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />
                                                    :
                                                    <img alt="Logo" src={bbanner} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} />
                                                :
                                                <img src={this.state.htmlelement} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />

                                        }
                                    </div>

                                    <AboutCourseForm
                                        handleSubmit={this.handleSubmit}
                                        onEdit={this.onEdit}
                                        onImageChange={this.onImageChange}
                                        isLoading={this.state.isLoading}
                                        data={this.state.formData}
                                        image={this.state.htmlelement}
                                        staff_list={this.state.staff_list}
                                    />
                                </div>
                            </div>
                            {/* <!--end::Container--> */}
                        </div>
                        {/* <!--end::Post--> */}
                    </div>
                    {/* <!--end::Content--> */}
                </div>
            </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        courseData: state.courseDetails,
        generalDetails: state.generalDetails,
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(AddAboutCourse);
