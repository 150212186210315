import React, {Component} from "react";
import { connect } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink} from "../../../resources/url";
import HeadlineForm from "./headlineform";
import Header from "../../Header/header";

class UpdateHeadline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            htmlelement: <i className="fa fa-user fa-8x" aria-hidden="true"/>,
            formData: {
                id: this.props.headlineData.EntryID,
                linkTitle: this.props.headlineData.LinkTitle,
                linkPath: this.props.headlineData.LinkPath,
                bgColour: this.props.headlineData.BGColour,
                messageMsg: this.props.headlineData.Message,
            },
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onUpdateHeadline();
    };

    async onUpdateHeadline() {
        let sendData = {
            ...this.state.formData,
            submittedBy: "E1792"
        };

        if(this.state.formData.linkTitle === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter headline title", "error")
            return false;
        }

        if(this.state.formData.linkPath === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter Link Path caption", "error")
            return false;
        }
        if(this.state.formData.bgColour === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select Background Color ", "error")
            return false;
        }
        if(this.state.formData.messageMsg === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter Message", "error")
            return false;
        }

        axios.patch(`${serverLink}news/headline/update`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.showAlert("SUCCESS", "NEWS ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the news details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '#/general/headline/headlinelist';
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                Headline
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                Update Headline
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3>UPDATE HEADLINE</h3>
                                </div>
                            </div>
                            <HeadlineForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                isLoading={this.state.isLoading}
                                data={this.state.formData}
                            />
                        </div>
                        {/* CONTENT END */}
                    </div>
                </div>
            </div>
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        headlineData: state.generalDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateHeadline);
