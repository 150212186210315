import React, { Component } from "react";
import bazeLogo from "../../images/logobaze.png";
import swal from "sweetalert";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { encryptData } from "../util/generalfunction";
import { setLoginDetails } from "../../actions/setgeneraldetailsaction";
import { connect } from "react-redux";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isLoading: false,
      locationData: {},
      isLoggedIn: false,
      formData: {
        username: "",
        password: "",
        userIP: "",
      },
    };
  }
  componentDidMount() {
    this.getLocationData();
  }

  getLocationData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    this.setState({ locationData: res.data });
  };

  onLoginClicked = async (e) => {
    e.preventDefault();
    const formData = this.state.formData;
    let sendData = {
      ...formData,
      userIP: this.state.locationData,
      password: encryptData(formData.password),
    };

    this.setState({ isLoading: "on" });
    if (this.state.formData.username === "") {
      this.setState({
        isLoading: false,
      });
      this.showAlert(
        "FORM VALIDATION ERROR",
        "Please enter your username",
        "error"
      );
      return false;
    }

    if (this.state.formData.password === "") {
      this.setState({
        isLoading: false,
      });
      this.showAlert(
        "FORM VALIDATION ERROR",
        "Please enter your password",
        "error"
      );
      return false;
    }

    await axios
      .post(`${serverLink}login/staff_login`, sendData)
      .then((data) => {
        const response = data.data.message;
        const userData = data.data.userData;
        this.setState({ isLoading: false });
        if (response === "invalid_login") {
          this.showAlert("Oops!", "Invalid Login Credential", "error");
          return false;
        } else if (response === "error") {
          this.showAlert(
            "Oops!",
            "Something went wrong. Please try again!",
            "error"
          );
          return false;
        } else if (response === "success") {
          window.sessionStorage.setItem("userPermission", userData.IsAdmin);
          window.sessionStorage.setItem("isLoggedIn", true);
          this.props.setOnLoginDetails(userData);
          this.showSuccessAlert("SUCCESS", "Login Successful", "success");
          return false;
        } else {
          this.showAlert(
            "Oops!",
            "Something went wrong. Please try again!",
            "error"
          );
          return false;
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error("ERROR: ", err);
      });
  };

  onEdit = (e) => {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  showAlert(title, msg, type) {
    return swal({
      title: title,
      text: msg,
      icon: type,
      button: "OK",
    });
  }

  showSuccessAlert(title, msg, type) {
    return swal({
      title: title,
      text: msg,
      icon: type,
      button: "OK",
    }).then((value) => {
      window.location.href = "#/dashboard";
      window.location.reload();
    });
  }
  render() {
    return (
      <div className="d-flex flex-column flex-root align-items-center">
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <a href="../../demo1/dist/index.html" className="mb-12">
              <img alt="Logo" src={bazeLogo} className="h-90px" />
            </a>
            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <form className="form w-100" onSubmit={this.onLoginClicked}>
                <div className="mb-10">
                  <h1 className="text-dark mb-3 text-center ">Admin Login </h1>
                </div>
                <div className="fv-row mb-10" style={{ textAlign: "left" }}>
                  <input
                    type="text"
                    className="form-control form-control-solid  mb-5"
                    name="username"
                    id="username"
                    onChange={this.onEdit}
                    value={this.state.username}
                    placeholder="Username"
                  />
                </div>
                <div className="fv-row mb-10" style={{ textAlign: "left" }}>
                  {/*<label className="required fs-5 fw-bold mb-2">Password</label>*/}
                  <input
                    type="password"
                    className="form-control form-control-solid  mb-5"
                    name="password"
                    id="password"
                    onChange={this.onEdit}
                    value={this.state.password}
                    placeholder="Password"
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                  <button
                    type="submit"
                    id="kt_modal_new_address_submit"
                    data-kt-indicator={this.state.isLoading}
                    className="btn btn-primary w-50 mb-9 mt-9"
                  >
                    <span className="indicator-label">LOGIN</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex flex-center flex-column-auto p-10">
            <div className="d-flex align-items-center fw-bold fs-6">
              <a href="#" className="text-muted text-hover-primary px-2">
                Home
              </a>
              <a href="#" className="text-muted text-hover-primary px-2">
                About
              </a>
              <a href="#" className="text-muted text-hover-primary px-2">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDisptachToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(null, mapDisptachToProps)(Login);
