import React, {Component} from "react";
import Sliderform from "./sliderform";
import axios from "axios";
import swal from 'sweetalert';
import {serverLink, showAlert} from "../../../resources/url";
import Header from "../../Header/header";
import {connect} from "react-redux";

class AddSlider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: "off",
			imagefile: "",
			htmlelement: "",
			formData: {
				title: "",
				caption: "",
				image: "",
				imagePosition: "",
				btnName: "",
				btnLink: "",
				photo: "",
				ImagePath: ""
			},
		}
	}
	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({
			isLoading: "on",
		});
		this.onAddSlider();
	};

	async onAddSlider() {
		let sendData = {
			...this.state.formData,
			submittedBy: this.props.loginDetails.staff_name,
		};

		if(this.state.formData.title === "") {
			this.setState({
				isLoading: false,
			});
			showAlert("FORM VALIDATION ERROR", "Please enter slider title", "error")
			return false;
		}

		// if(this.state.formData.caption === "") {
		// 	this.setState({
		// 		isLoading: false,
		// 	});
		// 	this.showAlert("FORM VALIDATION ERROR", "Please select slider caption", "error")
		// 	return false;
		// }
		if(this.state.formData.btnName === "") {
			this.setState({
				isLoading: false,
			});
			showAlert("FORM VALIDATION ERROR", "Please select button name", "error")
			return false;
		}
		if(this.state.formData.btnLink === "") {
			this.setState({
				isLoading: false,
			});
			showAlert("FORM VALIDATION ERROR", "Please select button link", "error")
			return false;
		}

		if(this.state.formData.imagePosition === "") {
			this.setState({
				isLoading: false,
			});
			showAlert("FORM VALIDATION ERROR", "Please enter image position", "error")
			return false;
		}

		axios.post(`${serverLink}slider/add_slider`, sendData)
			.then(result => {
				this.setState({
					isLoading: false,
				});
				const response = result.data.message;
				if(response === "news exist") {
					showAlert("Oops!", "Slider with the same title already exist", "error")
					return false;
				} else if (response === "failed") {
					showAlert("Oops!", "Something went wrong adding the slider. Please try again!", "error")
					return false;
				} else if (response === "success"){

					if (this.state.imagefile !== "") {
						const formData = new FormData();
						formData.append('photo', this.state.imagefile);
						formData.append('entry_id', result.data.entry_id)
						axios.post(`${serverLink}slider/uploadSlidersPhoto`, formData)
							.then(result => {
								this.setState({
									isLoading: false,
								});
							})
							.catch(err => {
								this.setState({
									isLoading: false,
								});
								console.error('ERROR', err);
							});
					}
					this.showAlert("SUCCESS", "SLIDER ADDED SUCCESSFULLY", "success")
					return false;
				} else {
					showAlert("Oops!", "Failed to add the slider details!", "error")
					return false;
				}

			})
			.catch(err => {
				this.setState({
					isLoading: false,
				});
				console.error('ERROR', err);
			});
	}

	onEdit = (e) => {
		const formData = this.state.formData;
		this.setState({
			formData: {
				...formData,
				[e.target.name]: e.target.value,
			},
		});
	};

	// onImageChange = (url) => {
	// 	const formData = this.state.formData;
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             ImagePath: url,
    //         },
    //     });
	// };

	onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0]
			if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
			} else {
				this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
				return false;
			}
			if (file.size > 5000000) {
				this.showAlert("Oops!", "max file size is 5mb", "error")
				return false;
			}

			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (e) => {
				this.setState({
					imagefile: event.target.files[0],
					htmlelement: e.target.result,
					image_name: file.name,
					view: file.mozFullPath,
				});
			};
		}
	};

	showAlert(title, msg, type){
		return swal({
			title: title,
			text: msg,
			icon: type,
			button: "OK",
		})
		
		.then((value) => {
			window.location.href = '#/general/slider/sliderslist';
		});
	}

	render() {
		return (
			<>
				<Header isFaculty={false}/>
			<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div className="toolbar" id="kt_toolbar">
					<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
						<div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
							<h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
								<span className="h-20px border-dark  border-start ms-3 mx-2"/>
								Slider
								<span className="h-20px border-dark  border-start ms-3 mx-2"/>
								Add Slider
							</h1>
						</div>
					</div>
				</div>
				<div className="post d-flex flex-column-fluid" id="kt_post">
					<div id="kt_content_container" className="container-fluid">
						{/* CONTENT START */}
						<div className="card mb-5 mb-xl-10">
							<div className="card-header">
								<div className="card-title">
									<h3>ADD SLIDER</h3>
								</div>
							</div>
							<Sliderform
								handleSubmit={this.handleSubmit}
								onEdit={this.onEdit}
								onImageChange={this.onImageChange}
								isLoading={this.state.isLoading}
								data={this.state.formData}
								image={this.state.htmlelement}
							/>
						</div>
						{/* CONTENT END */}
					</div>
				</div>
			</div>
				</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		loginDetails: state.loginDetails,
	};
};
export default connect(mapStateToProps, null)(AddSlider);