import swal from "sweetalert";

export const status = "Live";
export const serverLink =
  status === "Dev"
    ? "http://localhost:4480/"
    : "https://api.bazeuniversity.edu.ng:5001/";
export const checkImageURL = (url) => {
  if (!url) return false;
  else {
    const pattern = new RegExp(
      "^https?:\\/\\/.+\\.(png|jpg|jpeg|bmp|gif|webp)$",
      "i"
    );
    return pattern.test(url);
  }
};

export function showAlert(title, msg, type) {
  return swal({
    title: title,
    text: msg,
    icon: type,
    button: "OK",
  });
}

export function showConfirm(title, msg, type, action) {
  // type: error, success, warning, confirm
  return swal({
    title: title,
    text: msg,
    icon: type,
    buttons: true,
    dangerMode: true,
  });
}

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
export const simpleFileUploadAPIKey = "b46bd7bf5e235f7a2d0d7b7602ecce10";
export const simpleFileUploadFolderName = "baze-website";
