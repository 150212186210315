import React, {Component} from "react";
import axios from "axios";
import {serverLink} from "../../../../resources/url";
import swal from "sweetalert";
import SubcategoryForm from "./subcategoryform";
import {accessControl} from "../../../../permission/permission";

class AddSubCategory extends Component {
    constructor() {
        super();
        accessControl([1, 4]);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: <i className="fa fa-user fa-8x" aria-hidden="true"/>,
            formData: {
                categoryName: "",
                subcategoryName:""

            },
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddSubCategory();
    };

    async onAddSubCategory() {
        let sendData = {
            ...this.state.formData,
        };

        if(this.state.formData.categoryName === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select category name", "error")
            return false;
        }
        if(this.state.formData.subcategoryName === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter sub category name", "error")
            return false;
        }



        axios.post(`${serverLink}gallery/addsubcategory`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "name exist") {
                    this.showAlert("Oops!", "sub-category with the same name already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the sub-category. Please try again!", "error")
                    return false;
                } else if (response === "success"){

                    this.showAlert("SUCCESS", "SUB CATEGORY ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the sub-category details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }
    render() {
        return (
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">About
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                GALLERY
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                ADD SUB-CATEGORY
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3>ADD SUB-CATEGORY</h3>
                                </div>
                            </div>
                            <SubcategoryForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                isLoading={this.state.isLoading}
                                data={this.state.formData}
                            />

                        </div>
                        {/* CONTENT END */}
                    </div>
                </div>
            </div>
        )
    }
}

export default AddSubCategory;