import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AddGallery from "../about/gallery/addphoto";
import UpdatePhoto from "../about/gallery/updatephoto";
import Login from "../login/login";
import NotFound from "../notfound/notfound";


class PublicRoutes extends Component {
    render() {
        return (
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route path="*" element={<NotFound />} />

            </Routes>
        )

    }
}

export default PublicRoutes;