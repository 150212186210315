import React, { Component } from "react";
import "./App.css";
import PageRoutes from "./component/page_routes/page_routes";
import SideBar from "./component/sidebar/sidebar";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./component/login/login";
import PublicRoutes from "./component/page_routes/public_routes";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: window.sessionStorage.getItem('isLoggedIn')
    }
  }

  
  render() {
    return (
      <div className="App">
        {
          this.props.loginDetails !== "" ?

            <HashRouter>
              <SideBar />
              <PageRoutes />
            </HashRouter>

            :
            <Login/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      loginDetails: state.loginDetails,
  };
};

export default connect(mapStateToProps, null)(App);
