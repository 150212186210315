import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import swal from 'sweetalert';
import { formatDate, serverLink } from "../../resources/url";
import EventForm from "./eventform";
import Header from "../Header/header";

class UpdateEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            formData: {
                id: this.props.eventData.EntryID,
                Title: this.props.eventData.Title,
                Description: this.props.eventData.Description,
                EventDate: this.props.eventData.EventDate,
                StartTime: this.props.eventData.StartTime,
                EndTime: this.props.eventData.EndTime,
                Location: this.props.eventData.Location,
                FacultyCode: this.props.isFaculty ? this.props.facultyData.FacultyCode : "General",
                Status: this.props.eventData.Status,
                SubmittedBy: this.props.eventData.Author
            },
            multiImges: [],
            files_: []
        }
    }

    componentDidMount() {
        this.getUploadedFiles()
    }

    getUploadedFiles = () => {
        try {
            axios.get(`${serverLink}event/get-multi-images/${this.props.eventData.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    let rows = []
                    res.data.map((x) => {
                        rows.push({
                            file: { name: x.ImagePath },
                            preview: `${serverLink}public/uploads/events_uploads/${x.ImagePath}`,
                            uploadable: false,
                            EntryID: x.EntryID
                        })
                    })
                    this.setState({
                        multiImges: rows,
                        files_: rows
                    })
                }
            })
        } catch (error) {

        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onUpdateNews();
    };

    async onUpdateNews() {
        let sendData = {
            ...this.state.formData,
            InsertedBy: this.props.loginDetails.staff_name,
            EventDate: formatDate(this.state.formData.EventDate)
        };

        if (this.state.formData.Title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter event title", "error")
            return false;
        }

        if (this.state.formData.EventDate === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event date", "error")
            return false;
        }

        if (this.state.formData.StartTime === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event start time", "error")
            return false;
        }

        if (this.state.formData.EndTime === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event end time", "error")
            return false;
        }

        if (this.state.formData.Location === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select event location", "error")
            return false;
        }

        if (this.state.formData.Description === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter event content", "error")
            return false;
        }

        if (this.state.formData.Status === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select status", "error")
            return false;
        }


        axios.patch(`${serverLink}event/update`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success") {

                    if (this.state.multiImges.length > 0) {
                        const files_to_upload = this.state.multiImges.filter(x => x.uploadable === true)
                        if (files_to_upload.length > 0) {
                            for (let index = 0; index < files_to_upload.length; index++) {
                                const element = files_to_upload[index];
                                const fdt = new FormData();
                                fdt.append("file", element.file);
                                fdt.append('entry_id', this.props.eventData.EntryID)
                                fdt.append("InsertedBy", this.props.loginDetails.staff_name)
                                fdt.append("ImageTitle", this.props.eventData.Title)

                                axios.post(`${serverLink}event/multi-images-upload`, fdt)
                                    .then(result => {
                                        this.setState({
                                            isLoading: false,
                                        });
                                    })
                                    .catch(err => {
                                        this.setState({
                                            isLoading: false,
                                        });
                                        console.error('ERROR', err);
                                    });
                            }
                        }
                    }

                    this.showSuccessAlert("SUCCESS", "EVENT UPDATED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to update the event details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    MultiImageChange = (event) => {
        const file = event.target.files[0]
        if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        } else {
            this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
            return false;
        }
        if (file.size > 2000000) {
            this.showAlert("Oops!", "max file size is 2mb", "error")
            return false;
        }
        const objectUrl = URL.createObjectURL(file)
        this.state.files_.push({ file: file, preview: objectUrl, uploadable: true, EntryID: '' })
        this.setState({
            multiImges: this.state.files_
        })
    }


    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            this.props.isFaculty ?
                window.location.href = '#/faculty/event/list'
                : window.location.href = '#/event/general/list';
        });
    }

    handleRemove = (e, val) => {
        e.preventDefault();
        if (val.EntryID !== '') {
            axios.delete(`${serverLink}event/delete-event-gallery/${val.EntryID}`).then((res) => {
                if (res.data.message === "success") {
                    this.getUploadedFiles();
                }
            })
        } else {
            const multis_ = this.state.multiImges;
            var rem_files = multis_.filter(x => x.file.name !== val.file.name);
            this.setState({
                multiImges: rem_files
            })
        }
    }

    render() {
        return (
            <>
                <Header isFaculty={this.props.isFaculty ? true : false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Event
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    {this.props.isFaculty ? "Faculty" : "General"}
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Update Event
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>UPDATE EVENT</h3>
                                    </div>
                                </div>
                                <EventForm
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    MultiImageChange={this.MultiImageChange}
                                    multiImges={this.state.multiImges}
                                    handleRemove={this.handleRemove}
                                />
                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        eventData: state.generalDetails,
        facultyData: state.facultyDetails,
        loginDetails: state.loginDetails,

    };
};
export default connect(mapStateToProps, null)(UpdateEvent);
